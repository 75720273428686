"use client";

import { HeroSection } from "@/components/hero-section";
import { useSession } from "next-auth/react";
import { useRouter } from "next/navigation";
import { LoadingPage } from "@/components/loading-page";


export default function Page() {
	const {status, data: session} = useSession();
	const router = useRouter();

  
  if (status === "loading") {
	return(
		<div>
		</div>
	)
  }

   
  else if (status === "unauthenticated") {
    return (
      <main>
        <HeroSection />
      </main>
    );
  }
  else if(status === "authenticated"){
    if(session?.user?.estAdmin || session?.user?.estAdmin > 0){
      
      router.push("/admin/users/manage");
    }
  }

  router.push('/profil')
 
  
  return null; 
}
