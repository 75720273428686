/* eslint-disable react/no-unescaped-entities */
"use client";

import Link from "next/link";
import { motion } from "framer-motion";
import AnimatedText from "./component/animated-text";
import SpheresBackground from "./SpheresBackground";
import { useEffect } from "react";

// Animation variants for different elements
const container = {
	hidden: { opacity: 0 },
	show: {
		opacity: 1,
		transition: {
			staggerChildren: 0.3, // Staggering the animation of child elements
		},
	},
};

const fadeInUp = {
	hidden: { opacity: 0, y: 20 },
	show: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeOut" } },
};

export function HeroSection() {
	useEffect(() => {
		document.body.classList.add("overflow-y-hidden");
		return () => {
			document.body.classList.remove("overflow-y-hidden");
		};
	}, []);

	return (
		<section className="w-full h-screen my-0 py-0 flex items-center justify-center bg-gradient-to-b from-primary/20 to-background">
			<motion.div
				className="container px-4 md:px-6 text-center"
				variants={container}
				initial="hidden"
				animate="show"
			>
				<div className="space-y-6">
					{/* Animated Heading */}
					<motion.div variants={fadeInUp}>
						<AnimatedText
							tag="h1"
							text="Bienvenue sur GameSphère !"
							className="text-4xl font-extrabold tracking-tighter sm:text-5xl md:text-6xl lg:text-7xl"
						/>
					</motion.div>

					{/* Animated Paragraph */}
					<motion.p
						className="mx-auto max-w-[600px] text-muted-foreground md:text-xl"
						variants={fadeInUp}
					>
						Plongez dans un univers de jeux passionnants, connectez-vous avec
						d'autres joueurs et vivez des expériences inoubliables.
					</motion.p>
					{/* Animated Buttons with a bounce effect on hover */}
					<motion.div
						className="grid md:grid-cols-2 gap-8 md:gap-2 items-center"
						variants={fadeInUp}
					>
						<motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
							<Link
								href="/compte/courriel-verification"
								className="btn btn-secondary px-6 py-3 shadow-md shadow-secondary-darker"
							>
								Créer un compte
							</Link>
						</motion.div>
						<motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
							<Link
								href="/compte/connexion"
								className="btn btn-outline-dark px-6 py-3 text-md shadow-md shadow-text"
							>
								Se connecter
							</Link>
						</motion.div>
					</motion.div>
					<SpheresBackground />
				</div>
			</motion.div>
		</section>
	);
}
