// components/SpheresBackground.tsx
import { Canvas, useFrame } from "@react-three/fiber";
import { Sphere } from "@react-three/drei";
import { useRef } from "react";
import * as THREE from "three";

// Define the props for each animated sphere
interface AnimatedSphereProps {
	position: [number, number, number];
	color: string;
}

function AnimatedSphere({ position, color }: AnimatedSphereProps) {
	const sphereRef = useRef<THREE.Mesh>(null);
	const velocity = useRef<[number, number, number]>([
		(Math.random() - 0.5) * 0.01,
		(Math.random() - 0.5) * 0.01,
		(Math.random() - 0.5) * 0.01,
	]);

	// Animate sphere movement
	useFrame(() => {
		if (sphereRef.current) {
			const pos = sphereRef.current.position;
			pos.x += velocity.current[0];
			pos.y += velocity.current[1];
			pos.z += velocity.current[2];

			// Boundary detection: reverse direction if the sphere goes out of bounds
			if (pos.x > 5 || pos.x < -5) velocity.current[0] *= -1;
			if (pos.y > 5 || pos.y < -5) velocity.current[1] *= -1;
			if (pos.z > 5 || pos.z < -5) velocity.current[2] *= -1;
		}
	});

	return (
		<Sphere ref={sphereRef} args={[1, 32, 32]} position={position}>
			<meshStandardMaterial
				color={color}
				transparent
				opacity={0.6} // Make spheres slightly transparent
			/>
		</Sphere>
	);
}

const SpheresBackground = () => {
	const colors: string[] = ["#00FFD0", "#E300E7", "#FFD900"];

	return (
		<div className="">
		<Canvas
			camera={{ position: [0, 0, 10], fov: 75 }}
			style={{
				position: "absolute",
				top: 0,
				left: 0,
				width: "100%",
				height: "100%",
				zIndex: -1,
				overflow: "hidden"
			}}
		>
			<ambientLight intensity={0.4} />
			<pointLight position={[10, 10, 10]} />

			{/* Render multiple spheres with random positions and colors */}
			{Array(50)
				.fill(0)
				.map((_, index) => (
					<AnimatedSphere
						key={index}
						position={[
							(Math.random() - 0.5) * 15,
							(Math.random() - 0.5) * 15,
							(Math.random() - 0.5) * 15,
						]}
						color={colors[Math.floor(Math.random() * colors.length)]}
					/>
				))}
		</Canvas>
		</div>
	);
};

export default SpheresBackground;
