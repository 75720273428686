import { motion } from "framer-motion";

interface AnimatedTextProps {
  tag: keyof typeof motion; 
  text: string; // Le texte à animer
  className?: string;
}

function AnimatedText({ tag, text, className }: AnimatedTextProps) {
    const Tag = motion[tag] as React.ComponentType<any>;
  const letters = text.split("");

  return (
    <Tag
    className={className}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {letters.map((letter, index) => (
        <motion.span
          key={index}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: index * 0.1 }}
        >
          {letter}
        </motion.span>
      ))}
    </Tag>
  );
}

export default AnimatedText;
